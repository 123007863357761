/* Remove the underline for links */
.sidebar a {
  text-decoration: none;
}

.layout {
  display: flex; /* Align sidebar and content side by side */
  height: 100vh; /* Make the layout fill the entire viewport height */
}

.sidebar {
  width: 250px; /* Sidebar width */
  background-color: #3C3E49; 
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column; /* Stack the sidebar items vertically */
  justify-content: flex-start; /* Align items to the top */
}

.sidebar h2 {
  margin-bottom: 20px; /* Space below the title */
  color: white;
}

.sidebar-button {
  display: block; /* Stack buttons vertically */
  margin: 7px 0;
  padding: 10px; 
  width: 100%; 
  background-color: #4b5675; 
  color: white; 
  border: none; 
  border-radius: 8px; 
  cursor: pointer; 
}

.sidebar-button:hover {
  background-color: #5e6d90; 
}

.main-content {
  padding-top: 20px;
  flex: 1; 
  overflow-y: auto; /* Allow scrolling if content exceeds available height */
  background-color: #F4F4F4; /* bg color*/
}

/* Active tab styling */
.sidebar-button.active {
  background-color: #A25352;
  cursor: default; /* Disable cursor when active */
}

.sidebar-button:disabled {
  background-color: #22232a; 
  opacity: 0.6;
  cursor: default;
}

.dashboard-container {
  display: grid;
  grid-template-areas:
    "title title title"
    "revenue newClients webTraffic"
    "overview overview calendar";
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-template-rows: auto auto auto; /* Adjust height automatically */
  gap: 20px; /* Space between grid items */
  padding: 20px;
}

.dashboard-title-container {
  grid-area: title; /* Assign the title to the 'title' area */
}

.dashboard-title {
  padding-top: 30px;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin: 0; /* Remove extra margin to align perfectly */
  font: #000;
}

/* Widget styles */
.revenue-widget,
.new-clients-widget,
.web-traffic-widget,
.overview-widget,
.calendar-widget {
  background-color: #ffffff; /* Ensure the widget background is white */
  border: 1px solid #ccc; /* Light gray border for the box */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Space inside the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  margin-bottom: 20px; /* Add spacing between widgets */
  overflow: hidden; /* Prevent content overflow */
}

.revenue-widget {
  grid-area: revenue;
}

.new-clients-widget {
  grid-area: newClients;
}

.web-traffic-widget {
  grid-area: webTraffic;
}

.overview-widget {
  grid-area: overview;
}

.calendar-widget {
  grid-area: calendar;
}

.dashboard-container {
  padding: 20px;
}

.dashboard-title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px; /* Add some space between the title and the first widget */
}

.revenue-widget,
.new-clients-widget,
.web-traffic-widget,
.overview-widget,
.calendar-widget {
  margin-bottom: 20px; /* Add spacing between widgets */
}