.database-access {
    margin: 20px;
    font-family: Bitter, serif;
}

.collection-select {
    margin-bottom: 20px;
}

.error {
    color: red;
    font-weight: bold;
}

/* Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
    overflow-x: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

table th, table td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
    vertical-align: middle;
    word-wrap: break-word;
    white-space: normal;
}

table th {
    background-color: #f4f4f4;
    color: #333;
    font-size: 0.9em;
    font-weight: bold;
    position: sticky; /* Keeps header visible on scroll */
    top: 0;
    z-index: 1;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Alternating row colors */
table tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tr:hover {
    background-color: #f1f1f1;
}

/* Action Buttons */
.action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
}

.action-buttons button {
    padding: 5px 12px;
    background-color: #A25352;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 70px; /* Ensures all buttons have the same width */
    text-align: center;
}

/* Add bottom border to the actions column cell */
table td.action-buttons {
    padding-bottom: 40px;
    padding-left: 20px;
    border-bottom: 1px solid #ddd; /* Add bottom border to the cell */
}

.action-buttons button:hover {
    background-color: #7a3e3d;
}

/* Property Name Column Width */
table th:nth-child(2), /* Assuming 'propertyName' is the second column */
table td:nth-child(2) {
    width: 200px; /* Adjust to your preferred width */
    word-wrap: normal;
    white-space: nowrap; /* Prevent text wrapping */
}

/* Pagination */
.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pagination button {
    padding: 8px 50px;
    background-color: #be6666;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination span {
    display: inline-block; /* Ensures the text is treated as a block for alignment */
    text-align: center; /* Centers the text horizontally */
    line-height: 1.5; /* Adjusts line height if needed */
    font-size: 1em; /* Ensures the text size is consistent */
    padding: 0 20px 0 10px; /* Adds more padding on the right */
}

.pagination button:hover {
    background-color: #a35252;
}

.pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Close Button for Modals */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .action-buttons {
        flex-direction: column;
        gap: 5px;
    }
}
