.report-generation-container {
  padding: 20px;
  width: 60%;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.report-generation-button {
  padding: 10px 20px;
  background-color: #a25352;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.report-generation-button:hover {
  background-color: #7f4140;
}

.existing-client-bubble button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.bubble-button.selected {
  background-color: #a25352; /* Selected button */
  color: white;
  border: 2px solid #b47676; /* Add a border */
}

.bubble-button {
  background-color: #f0f0f0; /* Unselected button */
}

.client-action-bubble button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  justify-content: center;
}

.client-action-bubble .selected {
  background-color: #a25352;
  color: white;
}

.link-button {
  background-color: #a25352;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  margin: 10px;
}

.link-button:hover {
  background-color: #7f4140;
}

.view-forms, .manual-entry {
  margin-top: 10px;
}


.clients-table tbody tr:hover {
  background-color: #f2f2f2; /* Light grey color */
  cursor: pointer; /* Optional: Change the cursor to indicate interactivity */
}


.clients-table {
  width: 80%; /* Increase width to 90% of the container */
  max-width: 100%; /* Ensure the table doesn't overflow */
  margin: 20px auto; /* Center the table */
  border-collapse: collapse;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  table-layout: auto; /* Allows columns to adjust width automatically */
}

.clients-table th,
.clients-table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for better readability */
  text-align: center; 
  position: relative; /* Ensures relative positioning for button alignment */
}

.clients-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  font-size: 14px; 
}

.clients-table td {
  text-align: center; 
  font-size: 14px; 
  justify-content: center; 
  align-items: center;
}


.select-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  display: inline-block;
  justify-content: center; /* Horizontally centers the circle */
  align-items: center; /* Vertically centers the circle */
  margin: 0 auto; /* Centers the circle within the column */
}

.select-circle.selected {
  background-color: #a25352; /* Selected circle color */
}

.report-generation-button {
  padding: 10px 20px;
  background-color: #a25352;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.report-generation-button:hover {
  background-color: #7f4140;
}

.clients-table th.property-name-column,
.clients-table td.property-name-column {
  width: 200px; /* Adjust the width as needed */
  white-space: nowrap; /* Prevent text from wrapping */
}

/* Pagination styling */
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination span {
  display: inline-block; /* Ensures the text is treated as a block for alignment */
  text-align: center; /* Centers the text horizontally */
  line-height: 1.5; /* Adjusts line height if needed */
  font-size: 1em; /* Ensures the text size is consistent */
  padding: 0 20px 0 10px; /* Adds more padding on the right */
}

.pagination button {
  padding: 5px 10px;
  background-color: #be6666;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Adding some extra padding to the report generation button */
.report-generation-button {
  margin-top: 20px; /* Adds extra space between pagination and button */
}
