.client-info-container {

  position: relative;
}

/* Title centered */
.client-info-container h1 {
  text-align: center;
}

/* Form group styling */
.manual-entry-fields .form-group {
  margin-bottom: 15px;
  display: flex; /* Use flexbox to align label and input horizontally */
  align-items: center; /* Vertically align the label and input field */
}

/* Left-align field labels and prevent wrapping */
.manual-entry-fields label {
  padding-left: 20px;
  font-weight: bold;
  margin-right: 10px; /* Add spacing between the label and input field */
  width: 150px; /* Fixed width for labels */
  text-align: left; /* Align text to the left */
  white-space: nowrap; /* Prevent label text from wrapping */
}

/* Left-align field text and adjust width */
.manual-entry-fields input {
  width: 100%; /* Adjust to 100% of parent container */
  max-width: 400px; /* Limit maximum field width */
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left; /* Align text inside the fields to the left */
  margin-left: 150px; /* Add some spacing between the input field and its label */
}

/* Submit button */
.submit-container {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #a25352;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Apply Oswald font globally */
body {
  font-family: 'Bitter', sans-serif;
}

/* Use Bitter for paragraphs or specific elements */
p, .some-class {
  font-family: 'Bitter', serif;
}

/* For headers or titles, use Oswald */
h1, h2, h3 {
  font-family: 'Oswald', sans-serif;
}

.manual-entry-fields input {
  width: 100%;
  min-width: 200px; /* Prevent inputs from getting too small */
  max-width: 400px; /* Keep inputs at a reasonable maximum width */
}

/* For small screens */
@media (max-width: 768px) {
  .manual-entry-fields {
    width: 90%; /* Make the form take up most of the screen width */
  }

  .manual-entry-fields input {
    max-width: 100%; /* Let inputs expand to the full width */
  }
}
