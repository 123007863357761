/* CSS Reset for body and html */
html, body {
  margin: 0; 
  padding: 0; 
  height: 100%; 
  overflow: hidden; /*Prevent scrollbars if unnecessary */
}

/* Center the login container */
.login-container {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 260px;
    height: 100vh; 
    background-size: cover; 
    background-position: center; 
  }
  
  /* Style for the login box */
  .login-box {
    width: 280px;
    padding: 20px;
    background-color: rgb(168, 85, 85);
    border-radius: 8px;
    background-color: rgba(168, 85, 85, 0.97); /* RGBA with 0.8 opacity */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .logo_font {
    font-size: 1.5em;
    margin-bottom: 20px;
    margin-top: -10px;
    font-weight: bold;
    color: #db8a58;
  }
  
  /* Style for inputs */
  .login-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Style for the login button */
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #4D5673;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #3C3E48;
  }
  
  /* Logo image styling */
.logo {
    width: 200px; 
    height: auto; 
    margin-bottom: 20px;
  }

  /* Apply Oswald font globally */
body {
  font-family: 'Bitter', sans-serif;
}

/* Use Bitter for paragraphs or specific elements */
p, .some-class {
  font-family: 'Bitter', serif;
}

/* For headers or titles, use Oswald */
h1, h2, h3 {
  font-family: 'Oswald', sans-serif;
}
