.App {
    text-align: center;
  }
  
  .App-header {
    background-color: #352080;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .App-link {
    color: #61dafb;
  }

  .wrapper {
    padding: 20px;
}