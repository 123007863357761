.modal-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* This will center the modal */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  margin: 0 auto;  /* Ensure it's centered horizontally if needed */
}

.modal-warning {
  color: #e74c3c;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.modal-description-danger {
  color: red;
  font-size: 14px;
}

.modal-description {
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.5;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.delete-button {
  background-color: #be6666;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #7a3e3d;
}

.cancel-button {
  background-color: #bdc3c7;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.cancel-button:hover {
  background-color: #95a5a6;
}
